import React, { useEffect, useState } from 'react';
import baseURL from '../url';
import './Search.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import Swal from 'sweetalert2';

Modal.setAppElement('#root');

export default function Search() {
    const [numeros, setNumeros] = useState([]);
    const [loading, setLoading] = useState(true);
    const [identificacion, setIdentificacion] = useState('');
    const [filtroNumeros, setFiltroNumeros] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [rifas, setRifas] = useState([]);

    useEffect(() => {
        cargarNumeros();
        cargarRifas();
    }, []);

    const cargarNumeros = () => {
        setLoading(true);
        fetch(`${baseURL}/numeroGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setNumeros(data.numeros);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error al cargar números:', error);
                setLoading(false);
            });
    };

    const cargarRifas = () => {
        fetch(`${baseURL}/rifaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setRifas(data.rifas || []);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error al cargar rifas:', error);
                setLoading(true);
            });
    };

    const handleBuscar = () => {
        const numerosFiltrados = numeros?.filter(numero => numero?.identificacion === identificacion);
        setFiltroNumeros(numerosFiltrados);

        if (numerosFiltrados?.length === 0) {
            Swal.fire('No se encontraron números con ese Codigo Postal');
        }
    };

    const handleCloseModal = () => {
        // Limpiar búsqueda al cerrar el modal
        setIdentificacion('');
        setFiltroNumeros([]);
        setShowModal(false);
    };

    return (
        <div>
            <button onClick={() => setShowModal(true)} className="btnSearch">
                <FontAwesomeIcon icon={faSearch} /> Busca tu número
            </button>

            <Modal
                isOpen={showModal}
                onRequestClose={handleCloseModal}
                contentLabel="Buscar Número"
                className="search-modal"
                overlayClassName="search-overlay"
            >
                <div className='headerModal'>
                    <button onClick={handleCloseModal}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                    <h3>Buscar por Codigo Postal</h3>
                </div>
                <div className="modal-body">
                    <div className='deFLexSearch'>
                        <input
                            type="text"
                            value={identificacion}
                            onChange={(e) => setIdentificacion(e.target.value)}
                            placeholder="Ingresa Codigo Postal"
                            className="form-control"
                        />
                        <button onClick={handleBuscar} className="btn btn-success">
                            <FontAwesomeIcon icon={faSearch} /> Buscar
                        </button>
                    </div>

                    {filtroNumeros?.length > 0 && (
                        <div className="searchCards">
                            {loading ? (
                                <p>Cargando números...</p>
                            ) : (
                                <div>
                                    {filtroNumeros?.map(numero => (
                                        <div key={numero?.idNumero} className='cardSearch'>
                                            {rifas?.filter(filt => filt?.idRifa === numero?.idRifa)?.map(rifaF => (
                                                <React.Fragment key={rifaF.idRifa}>
                                                    <span><strong>Rifa: </strong> {rifaF?.nombre}</span>
                                                    {rifaF?.estado === 'finalizada' ? (
                                                        <strong>Rifa Finalizada</strong>
                                                    ) : (
                                                        <span><strong>Finaliza: </strong> {rifaF.finalizacion}</span>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                            <span><strong>Número: </strong> {numero.numero}</span>
                                            <span><strong>Codigo Postal: </strong> {numero.identificacion}</span>
                                            <span><strong>Nombre: </strong> {numero.nombre}</span>
                                            <span><strong>Estado: </strong> {numero.estado}</span>
                                            <span><strong>Dirección: </strong> {numero.direccion}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
}
