import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faArrowUp, faArrowDown, faSync, faEye } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import 'jspdf-autotable';
import baseURL from '../../url';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export default function NumerosData() {
    const [numeros, setNumeros] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [nuevoNombre, setNuevoNombre] = useState('');
    const [nuevoTelefono, setNuevoTelefono] = useState('');
    const [nuevoEstado, setNuevoEstado] = useState('');
    const [numero, setNumero] = useState({});
    const [identificacion, setIdentificacion] = useState({});
    const [direccion, setDireccion] = useState({});
    const [selectedSection, setSelectedSection] = useState('texto');
    const [filtroId, setFiltroId] = useState('');
    const [filtroEstado, setFiltroEstado] = useState('');
    const [filtroNombre, setFiltroNombre] = useState('');
    const [filtroNumero, setFiltroNumero] = useState('');
    const [ordenInvertido, setOrdenInvertido] = useState(false);
    const [rifas, setRifas] = useState([]);
    const [visibleCount, setVisibleCount] = useState(20);
    const handleShowMore = () => {
        setVisibleCount(prevCount => prevCount + 20);
    };
    useEffect(() => {
        cargarNumeros();
        cargarRifas();

    }, []);

    const cargarNumeros = () => {
        fetch(`${baseURL}/numeroGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setNumeros(data.numeros || []);
            })
            .catch(error => console.error('Error al cargar numeros:', error));
    };




    const cargarRifas = () => {
        fetch(`${baseURL}/rifaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setRifas(data.rifas || []);
                console.log(data.rifas);
            })
            .catch(error => console.error('Error al cargar datos:', error));
    };
    const eliminar = (idNumero) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/numeroDelete.php?idNumero=${idNumero}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        cargarNumeros();
                    })
                    .catch(error => {
                        console.error('Error al eliminar numero:', error);
                        toast.error(error);
                    });
            }
        });
    };

    const abrirModal = (item) => {
        setNumero(item);
        setNuevoNombre(item.nombre);
        setNuevoTelefono(item.telefono);
        setNuevoEstado(item.estado);
        setDireccion(item.direccion);
        setIdentificacion(item.identificacion);
        setModalVisible(true);
    };

    const cerrarModal = () => {
        setModalVisible(false);
    };

    const handleUpdateText = (idNumero) => {
        const payload = {
            nombre: nuevoNombre !== undefined ? nuevoNombre : numero.nombre,
            estado: nuevoEstado !== '' ? nuevoEstado : numero.estado,
            telefono: nuevoTelefono !== undefined ? nuevoTelefono : numero.telefono,
            direccion: direccion !== undefined ? direccion : numero.direccion,
            identificacion: identificacion !== undefined ? identificacion : numero.identificacion,

        };

        fetch(`${baseURL}/numeroPut.php?idNumero=${idNumero}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    Swal.fire(
                        'Error!',
                        data.error,
                        'error'
                    );
                } else {
                    Swal.fire(
                        'Editado!',
                        data.mensaje,
                        'success'
                    );
                    cargarNumeros();
                    cerrarModal();
                }
            })
            .catch(error => {
                console.error(error.message);
                toast.error(error.message);
            });
    };

    const filtrados = numeros.filter(item => {
        const idMatch = item?.idRifa?.toString().includes(filtroId);
        const NombreMatch = !filtroNombre || item?.nombre.toLowerCase().indexOf(filtroNombre.toLowerCase()) !== -1;
        const estadoMatch = !filtroEstado || item?.estado === filtroEstado;
        const numeroMatch = item?.numero?.toString().includes(filtroNumero);
        return idMatch && NombreMatch && estadoMatch && numeroMatch;
    });

    const recargar = () => {
        cargarNumeros();
    };
    const invertirOrden = () => {
        setNumeros([...numeros].reverse());
        setOrdenInvertido(!ordenInvertido);
    };
    const handleSectionChange = (section) => {
        setSelectedSection(section);
    };
    const descargarExcel = () => {
        const data = filtrados.map(item => ({
            Numero: item.numero,
            Nombre: item.nombre,
            Estado: item.estado,
            Telefono: item.telefono,
            Direccion: item.direccion,
            Identificacion: item.identificacion,
            Creacion: item.createdAt,


        }));

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Numeros');
        XLSX.writeFile(wb, 'numeros.xlsx');
    };

    const descargarPDF = () => {
        const pdf = new jsPDF();
        pdf.text('Lista de numeros', 10, 10);

        const columns = [
            { title: 'Numero', dataKey: 'numero' },
            { title: 'Nombre', dataKey: 'nombre' },
            { title: 'Estado', dataKey: 'estado' },
            { title: 'Telefono', dataKey: 'telefono' },
            { title: 'Direccion', dataKey: 'direccion' },
            { title: 'Identificacion', dataKey: 'identificacion' },
            { title: 'Creacion', dataKey: 'createdAt' },
        ];

        const data = filtrados.map(item => ({
            Numero: item.numero,
            Nombre: item.nombre,
            Estado: item.estado,
            Telefono: item.telefono,
            Direccion: item.direccion,
            Identificacion: item.identificacion,
            Creacion: item.createdAt,

        }));

        pdf.autoTable({
            head: [columns.map(col => col.title)],
            body: data.map(item => Object.values(item)),
        });

        pdf.save('numeros.pdf');
    };
    return (
        <div>
            <ToastContainer />

            <div className='deFlexContent'>

                <div className='deFlex2'>
                    <button className='excel' onClick={descargarExcel}><FontAwesomeIcon icon={faArrowDown} /> Excel</button>
                    <button className='pdf' onClick={descargarPDF}><FontAwesomeIcon icon={faArrowDown} /> PDF</button>
                </div>
                <div className='filtrosContain'>
                    <div className='inputsColumn'>
                        <button className='' onClick={recargar}>{String(filtrados?.length)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")} / {String(numeros?.length)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")} </button>
                    </div>
                    <div className='inputsColumn'>
                        <select value={filtroId} onChange={(e) => setFiltroId(e.target.value)}>
                            <option value="">Rifas</option>
                            {
                                rifas?.map(item => (
                                    <option value={item?.idRifa}>{item?.nombre}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='inputsColumn'>
                        <input type="number" value={filtroNumero} onChange={(e) => setFiltroNumero(e.target.value)} placeholder='Numero' />
                    </div>
                    <div className='inputsColumn'>
                        <input type="text" value={filtroNombre} onChange={(e) => setFiltroNombre(e.target.value)} placeholder='Nombre' />
                    </div>
                    <div className='inputsColumn'>
                        <select value={filtroEstado} onChange={(e) => setFiltroEstado(e.target.value)}>
                            <option value="">Estado</option>
                            <option value='disponible'>Disponible</option>
                            <option value='pagado'>Pagado</option>
                            <option value='apartado'>Apartado</option>
                        </select>
                    </div>
                    <button className='reload' onClick={recargar}><FontAwesomeIcon icon={faSync} /></button>
                    <button className='reverse' onClick={invertirOrden}>
                        {ordenInvertido ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
                    </button>

                </div>

            </div>
            {modalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>
                            <div className='deFlexBtnsModal'>
                                <button
                                    className={selectedSection === 'texto' ? 'selected' : ''}
                                    onClick={() => handleSectionChange('texto')}
                                >
                                    Editar Texto
                                </button>
                            </div>
                            <span className="close" onClick={cerrarModal}>
                                &times;
                            </span>
                        </div>
                        <div className='sectiontext' style={{ display: selectedSection === 'texto' ? 'flex' : 'none' }}>
                            <div className='flexGrap'>
                                <fieldset>
                                    <legend>Numero</legend>
                                    <input
                                        type="text"
                                        disabled
                                        value={numero?.numero}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Nombre</legend>
                                    <input
                                        type="text"
                                        value={nuevoNombre}
                                        onChange={(e) => setNuevoNombre(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Estado</legend>
                                    <select
                                        value={nuevoEstado !== '' ? nuevoEstado : numero.estado}
                                        onChange={(e) => setNuevoEstado(e.target.value)}
                                    >
                                        <option value={numero?.estado}>{numero?.estado}</option>
                                        <option value='disponible'>Disponible</option>
                                        <option value='pagado'>Pagado</option>
                                        <option value='apartado'>Apartado</option>
                                    </select>
                                </fieldset>
                                <fieldset >
                                    <legend>Telefono</legend>
                                    <input
                                        type="number"
                                        value={nuevoTelefono}
                                        onChange={(e) => setNuevoTelefono(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset >
                                    <legend>Codigo Postal</legend>
                                    <input
                                        type="number"
                                        value={identificacion}
                                        onChange={(e) => setIdentificacion(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset >
                                    <legend>Direccion</legend>
                                    <input
                                        type="text"
                                        value={direccion}
                                        onChange={(e) => setDireccion(e.target.value)}
                                    />
                                </fieldset>

                            </div>
                            <button className='btnPost' onClick={() => handleUpdateText(numero.idNumero)}>Guardar</button>
                        </div>
                    </div>
                </div>
            )}
            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            {/* <th>Id Numero</th> */}
                            <th>Rifa</th>
                            <th>Estado</th>
                            <th>Numero</th>
                            <th>Nombre</th>
                            <th>Telefono</th>
                            <th>Codigo Postal</th>
                            <th>Direccion</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtrados?.slice(0, visibleCount)?.map(item => (
                            <tr key={item.idNumero}>
                                {/* <td>{item.idNumero}</td> */}
                                {rifas
                                    .filter(rifaFilter => rifaFilter.idRifa === item.idRifa)
                                    .map(rifaFiltrada => (
                                        <td>{rifaFiltrada?.nombre}</td>
                                    ))
                                }
                                <td style={{
                                    color: item?.estado === 'disponible' ? '#008000' : item?.estado === 'pagado' ? '#FF0000' : '#DAA520',
                                }}>
                                    {`${item?.estado}`}
                                </td>
                                <td>{item.numero}</td>
                                <td>{item?.nombre}</td>
                                <td>{item.telefono}</td>
                                <td>{item.identificacion}</td>
                                <td>{item.direccion}</td>
                                <td>
                                    {/* <button className='eliminar' onClick={() => eliminar(item.idNumero)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button> */}
                                    <button className='editar' onClick={() => abrirModal(item)}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {filtrados?.length > visibleCount && (
                <button onClick={handleShowMore} id="show-more-btn">
                    Mostrar  más </button>
            )}
        </div>
    );
}
