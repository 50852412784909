import React, { useEffect, useState } from 'react';
import { Link as Anchor } from "react-router-dom";
import './NumerosMain.css'
import baseURL from '../../url';
export default function NumerosMain() {
    const [numeros, setNumeros] = useState([]);

    useEffect(() => {
        cargarNumeros();
    }, []);



    const cargarNumeros = () => {
        fetch(`${baseURL}/numeroGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setNumeros(data?.numeros?.reverse()?.slice(0, 5) || []);
            })
            .catch(error => console.error('Error al cargar numeros:', error));
    };

    return (


        <div className='table-containerProductos'>
            <div className='deFlexMore'>
                <h3>Ultimas {numeros?.length} numeros </h3>
                <Anchor to={`/dashboard/numeros`} className='logo'>
                    Ver más
                </Anchor>
            </div>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Numero</th>
                        <th>Estado</th>
                        <th>Nombre</th>
                        <th>Telefono</th>
                        <th>Codigo Postal</th>
                        <th>Direccion</th>
                    </tr>
                </thead>
                <tbody>
                    {numeros?.map(item => (
                        <tr key={item.idNumero}>
                            <td>{item.numero}</td>
                            <td style={{
                                color: item?.estado === 'disponible' ? '#008000' : item?.estado === 'pagado' ? '#FF0000' : '#DAA520',
                            }}>
                                {`${item?.estado}`}
                            </td>
                            <td>{item?.nombre}</td>
                            <td>{item.telefono}</td>
                            <td>{item.identificacion}</td>
                            <td>{item.direccion}</td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>

    );
};
